import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import isServerSide from '../../modules/is-server-side'
import { useCookies } from 'react-cookie'
import isStolApp from '../../modules/is-stolapp'
import { useWidgetContext } from '../../bundles/components/WidgetContext'
import options from '../../config/options'
import LoadScript from './components/LoadScript'
import consoleLogger from '../../modules/console-logger'

const Sourcepoint = () => {
  const [consoleLog] = consoleLogger('Sourcepoint')
  const isWidget = useWidgetContext()
  const [isConfigPrepared, setIsConfigPrepared] = useState(false);
  const [cookies] = useCookies(['cw'])
  let app = isStolApp() ? 'app' : cookies['cw']
  const config = {
    accountId: 1411,
    baseEndpoint: 'https://cdn.privacy-mgmt.com',
    isSPA: true,
    targetingParams: {
      cookiew: app,
    },
  }
  consoleLog('is widget', isWidget)
  if (isWidget && isStolApp()) {
    consoleLog('propertyHref', options.Bundles.backendHost)
    config.propertyHref = options.Bundles.backendHost
  }

  if (!isServerSide() && window && window.device_native_advertising_identifier) {
    config.authId = window.device_native_advertising_identifier
  }

  if (isConfigPrepared) {
    return <LoadScript />
  }
  return (
    <>
    <Helmet onChangeClientState={() => {setIsConfigPrepared(true);}}>
      <script>
        {`
 !function () { var e = function () { var e, t = "__tcfapiLocator", a = [], n = window; for (; n;) { try { if (n.frames[t]) { e = n; break } } catch (e) { } if (n === window.top) break; n = n.parent } e || (!function e() { var a = n.document, r = !!n.frames[t]; if (!r) if (a.body) { var i = a.createElement("iframe"); i.style.cssText = "display:none", i.name = t, a.body.appendChild(i) } else setTimeout(e, 5); return !r }(), n.__tcfapi = function () { for (var e, t = arguments.length, n = new Array(t), r = 0; r < t; r++)n[r] = arguments[r]; if (!n.length) return a; if ("setGdprApplies" === n[0]) n.length > 3 && 2 === parseInt(n[1], 10) && "boolean" == typeof n[3] && (e = n[3], "function" == typeof n[2] && n[2]("set", !0)); else if ("ping" === n[0]) { var i = { gdprApplies: e, cmpLoaded: !1, cmpStatus: "stub" }; "function" == typeof n[2] && n[2](i) } else a.push(n) }, n.addEventListener("message", (function (e) { var t = "string" == typeof e.data, a = {}; try { a = t ? JSON.parse(e.data) : e.data } catch (e) { } var n = a.__tcfapiCall; n && window.__tcfapi(n.command, n.version, (function (a, r) { var i = { __tcfapiReturn: { returnValue: a, success: r, callId: n.callId } }; t && (i = JSON.stringify(i)), e.source.postMessage(i, "*") }), n.parameter) }), !1)) }; "undefined" != typeof module ? module.exports = e : e() }();
 `}
      </script>
      <script>
        {`
            window._sp_ = window._sp_ || {};
            window._sp_ = {
              config: ${JSON.stringify(config)}
            }
          `}
      </script>
    </Helmet>
  </>
  )
}

export default Sourcepoint
